import ApplicationController from './application_controller'

export default class extends ApplicationController {
  connect () {
    super.connect();
  }

  checkbox_clicked(event) {
    this.element.closest("form").requestSubmit();
  }

}
